import { request } from "./index";

export const getAlbums = () => request("get", "/gallery/album");

export const getPictures = (data) => request("get", `/gallery/picture?album_id=${data.album_id}&size=${data.size}&page=${data.page}`);

export const getHighlights = (data) => request("get", `/gallery/picture?isHighlight=true&size=${data.size}&page=${data.page}`);

export const handleLike = (data) => request("post", "/gallery/picture/like", data);

