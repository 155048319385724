<template>
  <div class="d-flex flex-column">
    <vue-masonry-wall :items="data" :options="options">
      <template v-slot:default="{ item }">
        <v-card color="transparent" height="100%" flat>
          <img
            class="image pointer-hand rounded-lg"
            :src="item.url"
            @click="selectImage(item)"
          />

          <!-- floating -->
          <v-chip
            class="text-body-1 floating-tag mt-1 mr-1"
            style="background-color: rgba(0, 0, 0, 0.5)"
            label
            dark
            @click="submit(item)"
          >
            <span
              :class="
                handleLike(item.likes) ? 'secondary--text' : 'white--text'
              "
            >
              {{ item.likes.length + item.boost }}
            </span>

            <v-icon
              :color="handleLike(item.likes) ? 'secondary' : 'white'"
              size="18"
              class="ml-2"
            >
              {{ handleLike(item.likes) ? "mdi-heart" : "mdi-heart-outline" }}
            </v-icon>
          </v-chip>
        </v-card>
      </template>
    </vue-masonry-wall>

    <!-- image view -->
    <vue-easy-lightbox
      v-if="selected"
      :visible="visible"
      :imgs="selected.url"
      :index="0"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { handleLike } from "@/services/gallery";
import { displayAlert } from "@/utils";
import VueMasonryWall from "vue-masonry-wall";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  data() {
    return {
      options: {
        width: 300,
        padding: {
          2: 8,
          default: 12,
        },
      },
      visible: false,
      selected: {},
    };
  },

  props: {
    data: {
      type: Array,
      default: [],
    },
  },

  computed: {
    ...mapState(["user"]),
  },

  components: { VueMasonryWall, VueEasyLightbox },

  methods: {
    async submit(data) {
      if (!this.$store.getters.authenticated) {
        this.displayAlert("Acesse sua conta para", 2);
        return this.$router.push({ path: "/auth" });
      }

      try {
        const payload = {
          picture_id: data._id,
          like: !this.handleLike(data.likes),
        };

        await handleLike(payload).then(() => {
          this.$emit("refresh");
        });
      } catch (err) {
        console.log(err.data);
      }
    },

    handleLike(data) {
      if (!this.$store.getters.authenticated) return false;
      return data.includes(this.user._id);
    },

    selectImage(data) {
      Object.assign(this.selected, data);
      this.visible = true;
    },

    handleHide() {
      this.visible = false;
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped>
.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  line-height: 0;
  display: block;
}

.floating-tag {
  position: absolute;
  top: 0;
  right: 0;
}
</style>